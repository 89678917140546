import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

import Content from './Content';
import Contact from './Contact';
import Footer from './Footer';

import { media } from './theme';
import useSiteContext from './SiteContext';


const HomePage = ({ title, _rawContent, mainImage }) => {

  const { viewport, mobile } = useSiteContext();

  return (
    <HomePageWrapper className="page-wrapper home" viewHeight={viewport.height}>
      <MainImage fluid={mainImage.asset.fluid} alt={mainImage.alt} objectFit="cover" />
      <HomeContentWrapper>
        <HomeContent className="home-content" main>{_rawContent}</HomeContent>
        {!mobile && (
          <Contact className="home-contact" big />
        )}
      </HomeContentWrapper>
      {!mobile && <Footer fullscreen white />}
    </HomePageWrapper>
  );
};

const HomePageWrapper = styled.div`
  ${media.break`
    height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop}px)`};
    height: ${({ viewHeight, theme }) => viewHeight - theme.headerHeight.desktop}px;
    position: relative;
  `}
`;

const MainImage = styled(Img)`
  width: 100%;
  height: 100%;
`;

const HomeContentWrapper = styled.div`
  ${media.break`
    position: absolute;
    width: 40%;
    top: 10%;
    right: 5%;
  `}
  .home-contact {
    padding: 1rem 2rem;
    display: inline-block;
  }
`;

const HomeContent = styled(Content)`
  background: ${({ theme }) => theme.purple};
  color: white;
  font-size: 2.3rem;
  font-weight: ${({ theme }) => theme.font.regular};
  ${media.break`
    padding: 1rem 2rem;
    margin-bottom: 4rem;
  `}
  
`;

export default HomePage;