import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import HomePage from '../components/HomePage';

const IndexPage = ({ data, location }) => {
  const { sanityHomePage } = data;
  return (
    <Wrapper home seo={{seoSettings: sanityHomePage.seoSettings, title: sanityHomePage.title }}  location={location}>
      <HomePage {...data.sanityHomePage} />
    </Wrapper>
  )
};

export const HomePageQuery = graphql`
  query homePageQuery {
    sanityHomePage(_id: {eq: "homePage"}) {
      title
      _rawContent
      mainImage {
        alt
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default IndexPage
